window.MORE_ENV = {
    apiEndpoint: 'https://api.moreapp.dev/api/v1.0',
    apiEndpointV2: 'https://api.moreapp.dev/api/v2',
    hydraPublicUrl: 'https://auth.moreapp.dev',
    countriesEndpoint: 'https://countries.moreapp.dev',
    featureSwitches: {},
    moreappWebsite: 'https://moreapp.dev',
    platformUrlProduction: 'https://platform.moreapp.dev',
    webClientUrl: 'https://app.moreapp.dev',
    moreIosDownloadLink: 'https://itunes.apple.com/en/app/moreapp/id839543670',
    moreAndroidDownloadLink: 'https://play.google.com/store/apps/details?id=com.moreapp',
    freshChatToken: '3089d94a-34f4-4ba8-965f-726194a8e3de',
    betaPlatformEndpoint: 'https://beta.platform.moreapp.dev',
    postHogToken: 'phc_F9Gco60fr7og7r27SJiIODm9JfBs8iHQCgjGt9vyAm9',
    postHogApiHost: 'https://egel.moreapp.dev',
    postHogUiHost: 'https://eu.posthog.com',
    environment: 'development'
};
